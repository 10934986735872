import axios from 'axios';
import { getTokenHeader } from '@theark/react-common';
import { ArkState } from '../types';

export enum CollectionActionType {
	CollectionNotification = 'CollectionNotification',
	CollectionNotificationError = 'CollectionNotificationError',
	CollectionNotificationLoading = 'CollectionNotificationLoading',
}

export enum CollectionRequestType {
	CollectionNotification,
	CollectionNotificationError,
	CollectionNotificationLoading,
}

export interface CollectionNotificationLoading {
	type: CollectionActionType.CollectionNotificationLoading;
	payload: any;
}

export interface CollectionNotificationError {
	type: CollectionActionType.CollectionNotificationError;
	payload: any;
}

export interface CollectionNotification {
	type: CollectionActionType.CollectionNotification;
	payload: any;
}
export type CollectionActions = CollectionNotification | CollectionNotificationError | CollectionNotificationLoading;

export const collectionNotification = (payload: any): CollectionNotification => {
	return {
		type: CollectionActionType.CollectionNotification,
		payload,
	};
};
export const collectionNotificationError = (payload: string): CollectionNotificationError => {
	return {
		type: CollectionActionType.CollectionNotificationError,
		payload,
	};
};
export const collectionNotificationLoading = (payload: boolean): CollectionNotificationLoading => ({
	type: CollectionActionType.CollectionNotificationLoading,
	payload,
});

export const createCollectionNotification = async (id: any, formData: any, arkState: ArkState, dispatch: any) => {
	dispatch(collectionNotificationLoading(true));
	const data = new FormData();
	Object.keys(formData).forEach((key) => {
		data.append(key, formData[key]);
	});
	try {
		const res: any = await axios('/api/collection/notify/' + id, {
			method: 'POST',
			headers: getTokenHeader(arkState?.user?.accessToken || ''),
			data,
		});
		if (res?.data?.success) {
			dispatch(collectionNotification(res?.data?.metadata));
		}
	} catch (e: any) {
		console.log('Unable to fetch wallet detail.', e.response.data.metadata.message);
		dispatch(collectionNotificationError(e.response.data.metadata.message));
	}
};
