import { ListOrdersResponse } from '@imtbl/core-sdk';
import { ICollection } from '../types';

export type MarketPlaceState = {
	marketPlaceOrdersCount: number;
	marketPlaceCollectionList: ImxCollectionListType;
	marketPlaceOrders?: ListOrdersResponse;
	marketPlaceCollections?: Array<ICollection>;
	remaining?: number;
	cursor?: string;
};

export type ImxCollectionListType = {
	assets: Array<ICollection>;
	cursor: string;
};

export enum MarketPlaceRequestType {
	GetMarketPlaceOrders = 'GetMarketPlaceOrders',
	GetMarketPlaceCollectionList = 'GetMarketPlaceCollectionList',
}
