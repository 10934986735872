import { Balance } from '@imtbl/core-sdk';
import { ethers } from 'ethers';
import { formatEther, parseEther } from 'ethers/lib/utils';
import { convertEtherToUSD } from '../../common/web3';
import { generateWalletConnection, getDefaultProvider, getExternalProvider, getWeb3Provider, IMX_CLIENT } from '../../utils';
import { removeRequestInProcess, requestInProcess } from '../actions';
import { ProviderPreference } from '../types';

export enum WalletActionType {
	WalletDetail = 'WalletDetail',
	WalletDetailError = 'WalletDetailError',
	WalletDetailLoading = 'WalletDetailLoading',
	DepositeProcessing = 'DepositeProcessing',
	DepositeSuccess = 'DepositeSuccess',
	DepositeError = 'DepositeError',
	Preparing = 'Preparing',
	WalletBalance = 'WalletBalance',
	WithdrawRequestProcessing = 'WithdrawRequestProcessing',
	WithdrawRequestSuccess = 'WithdrawRequestSuccess',
	WithdrawRequestError = 'WithdrawRequestError',
	WithdrawCompleteProcessing = 'WithdrawCompleteProcessing',
	WithdrawCompleteSuccess = 'WithdrawCompleteSuccess',
	WithdrawCompleteError = 'WithdrawCompleteError',
	Withdrawable = 'Withdrawable',
}

export enum WalletRequestType {
	WalletDetail,
	WalletDetailError,
	WalletDetailLoading,
	DepositeProcessing,
	DepositeSuccess,
	DepositeError,
	Preparing,
	WalletBalance,
	WithdrawRequestProcessing,
	WithdrawRequestSuccess,
	WithdrawRequestError,
	WithdrawCompleteProcessing,
	WithdrawCompleteSuccess,
	WithdrawCompleteError,
	Withdrawable,
}

export interface WalletDetail {
	type: WalletActionType.WalletDetail;
	payload: any;
}

export interface Preparing {
	type: WalletActionType.Preparing;
	payload: any;
}

export interface Withdrawable {
	type: WalletActionType.Withdrawable;
	payload: any;
}

export interface WalletBalance {
	type: WalletActionType.WalletBalance;
	payload: any;
}

export interface WalletDetailLoading {
	type: WalletActionType.WalletDetailLoading;
	payload: any;
}

export interface WalletDetailError {
	type: WalletActionType.WalletDetailError;
	payload: any;
}
export interface DepositeProcessing {
	type: WalletActionType.DepositeProcessing;
	payload: boolean;
}
export interface DepositeError {
	type: WalletActionType.DepositeError;
	payload: { message: string };
}
export interface DepositeSuccess {
	type: WalletActionType.DepositeSuccess;
	payload: boolean;
}

export interface WithdrawCompleteProcessing {
	type: WalletActionType.WithdrawCompleteProcessing;
	payload: boolean;
}
export interface WithdrawCompleteSuccess {
	type: WalletActionType.WithdrawCompleteSuccess;
	payload: boolean;
}
export interface WithdrawCompleteError {
	type: WalletActionType.WithdrawCompleteError;
	payload: {
		isError: boolean;
		message: string;
	};
}
export interface WithdrawRequestProcessing {
	type: WalletActionType.WithdrawRequestProcessing;
	payload: boolean;
}
export interface WithdrawRequestSuccess {
	type: WalletActionType.WithdrawRequestSuccess;
	payload: boolean;
}
export interface WithdrawRequestError {
	type: WalletActionType.WithdrawRequestError;
	payload: boolean;
}
export type WalletActions =
	| WalletDetail
	| WalletDetailError
	| WalletDetailLoading
	| DepositeProcessing
	| DepositeSuccess
	| Preparing
	| WalletBalance
	| WithdrawRequestProcessing
	| WithdrawRequestSuccess
	| WithdrawRequestError
	| WithdrawCompleteProcessing
	| WithdrawCompleteSuccess
	| WithdrawCompleteError
	| Withdrawable
	| DepositeError;

export const walletBalance = (payload: any): WalletBalance => {
	return {
		type: WalletActionType.WalletBalance,
		payload,
	};
};
export const walletDetail = (payload: any): WalletDetail => {
	return {
		type: WalletActionType.WalletDetail,
		payload,
	};
};
export const walletDetailError = (payload: boolean): WalletDetailError => ({
	type: WalletActionType.WalletDetailError,
	payload,
});
export const walletDetailLoading = (payload: boolean): WalletDetailLoading => ({
	type: WalletActionType.WalletDetailLoading,
	payload,
});

export const depositeProcessing = (payload: boolean): DepositeProcessing => ({
	type: WalletActionType.DepositeProcessing,
	payload,
});

export const depositeSuccess = (payload: boolean): DepositeSuccess => ({
	type: WalletActionType.DepositeSuccess,
	payload,
});

export const depositeError = (payload: { message: string }): DepositeError => ({
	type: WalletActionType.DepositeError,
	payload,
});

export const withdrawRequestProcessing = (payload: boolean): WithdrawRequestProcessing => ({
	type: WalletActionType.WithdrawRequestProcessing,
	payload,
});

export const withdrawRequestSuccess = (payload: boolean): WithdrawRequestSuccess => ({
	type: WalletActionType.WithdrawRequestSuccess,
	payload,
});

export const withdrawRequestError = (payload: boolean): WithdrawRequestError => ({
	type: WalletActionType.WithdrawRequestError,
	payload,
});

export const withdrawCompleteProcessing = (payload: boolean): WithdrawCompleteProcessing => ({
	type: WalletActionType.WithdrawCompleteProcessing,
	payload,
});

export const withdrawCompleteSuccess = (payload: boolean): WithdrawCompleteSuccess => ({
	type: WalletActionType.WithdrawCompleteSuccess,
	payload,
});

export const withdrawCompleteError = (payload: { isError: boolean; message: string }): WithdrawCompleteError => ({
	type: WalletActionType.WithdrawCompleteError,
	payload,
});

export const setWithdrawable = (payload: any): Withdrawable => ({
	type: WalletActionType.Withdrawable,
	payload,
});

export const setPreparing = (payload: any): Preparing => ({
	type: WalletActionType.Preparing,
	payload,
});

export const fetchWalletDetail = async (dispatch: any, address: any) => {
	dispatch(requestInProcess(WalletActionType.WalletDetailLoading));

	try {
		const balancesApi = IMX_CLIENT.balanceApi;
		const userBalance = (await getDefaultProvider.getBalance(address)) as unknown as ethers.BigNumberish;
		const ethBalance = parseFloat(formatEther(userBalance)).toFixed(3);
		dispatch(walletBalance(ethBalance));

		const l2Bal = await balancesApi.listBalances({ owner: address });
		const ethIndex = l2Bal.data.result.findIndex((token: Balance) => token.symbol === 'ETH');
		if (ethIndex !== -1) {
			const balances = l2Bal.data.result[ethIndex];

			if (balances.balance) {
				const usd = await convertEtherToUSD(String(balances.balance));
				dispatch(
					walletDetail({
						usd,
						ethBalance: Number.parseFloat(formatEther(balances.balance)).toFixed(3),
					})
				);
			}
			if (balances.withdrawable) {
				dispatch(
					setWithdrawable({
						usd: parseInt((await convertEtherToUSD(String(balances.withdrawable))) ?? ''),
						ethBalance: Number.parseFloat(formatEther(balances.withdrawable)),
					})
				);
			}
			if (balances.preparing_withdrawal) {
				dispatch(
					setPreparing({
						usd: parseInt((await convertEtherToUSD(String(balances.preparing_withdrawal))) ?? ''),
						ethBalance: Number.parseFloat(formatEther(balances.preparing_withdrawal)),
					})
				);
			}
		}
	} catch (e) {
		console.log('Unable to fetch wallet detail.', e);
		dispatch(walletDetailError(false));
	} finally {
		dispatch(removeRequestInProcess(WalletActionType.WalletDetailLoading));
	}
};

export const prepareWithdrawalETH = async (dispatch: any, provider: ProviderPreference, prepareAmount: number) => {
	dispatch(withdrawRequestProcessing(true));

	const externalProvider = await getExternalProvider(provider);
	const l1Provider = await getWeb3Provider(externalProvider as any);
	const walletConnection = await generateWalletConnection(l1Provider);
	await IMX_CLIENT.prepareWithdrawal(walletConnection, {
		amount: parseEther(prepareAmount.toString()).toString(),
		type: 'ETH',
	})
		.then(() => {
			dispatch(withdrawRequestSuccess(true));
		})
		.catch((e: any) => {
			console.log('error captured testing', e);
			dispatch(withdrawRequestError(true));
		});
};

export const completeWithdrawalETH = async (dispatch: any, provider: ProviderPreference) => {
	dispatch(withdrawCompleteProcessing(true));

	try {
		const externalProvider = await getExternalProvider(provider);
		const l1Provider = await getWeb3Provider(externalProvider as any);
		const walletConnection = await generateWalletConnection(l1Provider);

		await IMX_CLIENT.completeWithdrawal(walletConnection, { type: 'ETH' });
		dispatch(withdrawCompleteSuccess(true));
	} catch (error: any) {
		console.error('Withdrawal completion error:', error);

		let errorMessage = 'Something went wrong while completing your withdrawal.';

		if (error.code === 'INSUFFICIENT_FUNDS' || error.message?.toLowerCase().includes('insufficient funds')) {
			errorMessage = 'Insufficient ETH in your wallet to cover the gas fees.';
		} else if (error.message?.includes('user rejected')) {
			errorMessage = 'Transaction was rejected.';
		} else if (error.message?.includes('Magic RPC Error')) {
			errorMessage = 'Network connection error. Please try again.';
		}

		dispatch(
			withdrawCompleteError({
				isError: true,
				message: errorMessage,
			})
		);

		throw new Error(errorMessage);
	} finally {
		dispatch(withdrawCompleteProcessing(false));
	}
};

export const depositAmountToETH = async (dispatch: any, provider: ProviderPreference, depositAmount: number) => {
	dispatch(depositeProcessing(true));

	try {
		let isSuccess: boolean = false;
		const usersApi = IMX_CLIENT.usersApi;

		const externalProvider = await getExternalProvider(provider);
		const l1Provider = await getWeb3Provider(externalProvider as any);
		const walletConnection = await generateWalletConnection(l1Provider);

		const amount = parseEther(depositAmount.toString());

		// Check user's balance before attempting deposit
		const balance = await l1Provider.getBalance(await walletConnection.ethSigner.getAddress());
		if (balance.lt(amount)) {
			dispatch(depositeError({ message: 'Insufficient balance for deposit and gas fees.' }));
			return;
		}

		const isRegistered = await IMX_CLIENT.isRegisteredOnchain(walletConnection);

		// check is user is registered on chain and then deposit amount to user's account
		if (isRegistered) {
			await IMX_CLIENT.deposit(walletConnection.ethSigner, {
				type: 'ETH',
				amount: amount.toString(), // Amount in wei
			});
			isSuccess = true;
		} else {
			const l1Address = await walletConnection.ethSigner.getAddress();
			const l2Address = await walletConnection.starkSigner.getAddress();

			//register user
			await usersApi.getSignableRegistration({
				getSignableRegistrationRequest: {
					ether_key: l1Address,
					stark_key: l2Address,
				},
			});

			const depositResponse = await IMX_CLIENT.deposit(walletConnection.ethSigner, {
				type: 'ETH',
				amount: amount.toString(), // Amount in wei
			});
			console.log('depositResponse', depositResponse);
			isSuccess = true;
		}
		dispatch(depositeSuccess(isSuccess));
	} catch (e: any) {
		console.log('error captured testing', e);
		if (e.message.includes('insufficient funds')) {
			dispatch(depositeError({ message: 'Insufficient balance for deposit and gas fees.' }));
		} else {
			dispatch(depositeError({ message: 'An error occurred during deposit. Please try again.' }));
		}
	} finally {
		dispatch(depositeProcessing(false));
	}
};
