export const colors = {
	colors: {
		gdl: 'rgba(250, 232, 70, 1)',
		gdr: 'rgba(71, 139, 162, 1)',
		alert_gdl: '#ed4050',
		alert_gdr: '#71a247',
		white: '#ffffff',
		cardBackground: 'rgba(255, 255, 255, 0.19)',
	},
};
