import React, { useContext, useEffect, useState } from 'react';
import { IoLogoFacebook, IoLogoInstagram, IoLogoTwitter } from 'react-icons/io';
import {
	Box,
	Button,
	Container,
	Flex,
	FormControl,
	Heading,
	IconButton,
	Input,
	Link,
	Spacer,
	Text,
	useBreakpointValue,
	useToast,
	VStack,
} from '@chakra-ui/react';
import { ConfigContext } from '../../contexts/ConfigContext';
import { ConfigKey, ISiteConfig, Nullable } from '../../store/types';
import { getSiteConfigByKey } from '../../store/actions';
import { API_ENDPOINT, API_ENV } from '@theark/react-common';
import axios from 'axios';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { format } from 'date-fns';
import fallBackLogo from '../../assets/images/logo.svg';
import ImageWithFallback from '../ImageWithFallback';

interface Social {
	name: string;
	className?: string;
	link?: string;
}

export const Footer = () => {
	const { state: configState } = useContext(ConfigContext);
	const toast = useToast();
	const width = useBreakpointValue({ base: 100, md: 240 });
	const [footerLogo, setFooterLogo] = useState('');
	const [name, setName] = useState('The ARK');
	const [socials, setSocials] = useState<Social[]>([
		{
			name: 'facebook',
			className: 'fb',
		},
		{
			name: 'instagram',
			className: 'insta',
		},
		{
			name: 'twitter',
			className: 'tw',
		},
	]);
	const [isSubscribing, setIsSubscribing] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [subscriber, setSubscriber] = useState('');

	useEffect(() => {
		const socialConfigKeys: Record<string, ConfigKey> = {
			facebook: ConfigKey.SOCIAL_FACEBOOK,
			instagram: ConfigKey.SOCIAL_INSTAGRAM,
			twitter: ConfigKey.SOCIAL_TWITTER,
		};

		for (const socialName in socialConfigKeys) {
			const data = getSiteConfigByKey(configState?.siteConfig, socialConfigKeys[socialName]);
			if (data) {
				setSocials((prev) => prev.map((item) => (item.name === socialName ? { ...item, link: data.VALUE } : item)));
			}
		}

		const siteData: Nullable<ISiteConfig> = getSiteConfigByKey(configState?.siteConfig, ConfigKey.SITE_FOOTER_DARK);
		if (siteData) {
			setFooterLogo(siteData.VALUE);
		}
		const nameData = getSiteConfigByKey(configState?.siteConfig, ConfigKey.SITE_NAME);
		if (nameData) {
			setName(nameData.VALUE);
		}
	}, [configState]);

	const validateEmail = (email: string) => {
		return email.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	};

	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSubscriber(e.target.value);
	};

	const onSubscribeHandler = async () => {
		setIsSubscribing(true);
		if (subscriber.trim() && validateEmail(subscriber)) {
			axios
				.post(`${API_ENDPOINT}/${API_ENV}/subscribe`, {
					email: subscriber,
				})
				.then(() => {
					setIsSubmitted(true);
					toast({
						title: 'Subscribed successfully',
						description: 'You have been subscribed successfully',
						status: 'success',
						duration: 5000,
						isClosable: true,
					});
				})
				.catch((e) => {
					console.log(e.message);
					toast({
						title: 'Error',
						description: 'Unable to subscribe or already subscribed',
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				});
		}
		setIsSubscribing(false);
	};

	return (
		<>
			<Container as="div" mt={20} maxW="container.lg" className="footerPage">
				<Box borderY="1px" paddingY="14" mb="8" borderColor="gray.200">
					<Flex>
						<Box width="300px">
							<Heading>
								<ImageWithFallback
									alt="logo"
									src={fallBackLogo}
									fallbackSrc={footerLogo}
									width={width}
									height={width}
									priority
									quality={100}
									style={{ cursor: 'pointer', objectFit: 'cover' }}
								/>
							</Heading>
						</Box>
						<Spacer />
						<Box>
							<Heading mb={4} fontSize="18">
								Legal
							</Heading>
							<ul>
								<li>
									<Link href="/story"> Story </Link>
								</li>
								<li>
									<Link href="/terms"> Terms </Link>
								</li>
								<li>
									<Link href="/social"> Social </Link>
								</li>
								<li>
									<Link href="/privacy"> Privacy </Link>
								</li>
								<li>
									<Link href="/contact"> Contact </Link>
								</li>
							</ul>
						</Box>
						<Spacer />
						<Box width="420px" ml={{ base: 10, md: 0 }}>
							<Heading mb={4} fontSize="18">
								Stay in touch
							</Heading>
							<form>
								<Flex display={{ base: 'block', md: 'flex' }}>
									<Box mb={{ base: 3, md: 0 }}>
										<FormControl>
											<Input
												id="email"
												type="email"
												variant="outline"
												placeholder="Enter your email"
												value={subscriber}
												onChange={onChangeHandler}
											/>
										</FormControl>
									</Box>
									<Box ml={{ base: 0, md: 5 }}>
										<Button
											onClick={onSubscribeHandler}
											isLoading={isSubscribing}
											height={10}
											width={{ base: '100%', md: 'initial' }}
											className="buttonGradient"
											px="50"
											leftIcon={isSubmitted ? <IoCheckmarkCircleOutline /> : <></>}
										>
											Subscribe
										</Button>
									</Box>
								</Flex>
							</form>
						</Box>
					</Flex>
				</Box>
				<Flex>
					<VStack className="copyRight">
						<Text>
							© {new Date().getFullYear()} {`${name}`} Inc. All rights reserved.
						</Text>
						<Text as="em" align="left" fontSize="xs">
							{process.env.NEXT_PUBLIC_COMMIT_HASH ? (
								<>
									Version: {process.env.NEXT_PUBLIC_COMMIT_HASH} (
									{process.env.NEXT_PUBLIC_BUILD_TIME
										? format(new Date(process.env.NEXT_PUBLIC_BUILD_TIME), 'PPpp')
										: 'Unknown Build Time'}
									)
								</>
							) : (
								<>Manual Build</>
							)}
						</Text>
					</VStack>
					<Spacer />
					<Box>
						{socials.map((item, index) => {
							if (!item.link) return null;
							return (
								<Link key={index} href={item.link} target="_blank">
									<IconButton
										key={index}
										aria-label={item.name}
										className={item.className}
										bg="#f5f6f7"
										isRound={true}
										mr="4"
									>
										{item.name === 'facebook' ? (
											<IoLogoFacebook />
										) : item.name === 'instagram' ? (
											<IoLogoInstagram />
										) : (
											<IoLogoTwitter />
										)}
									</IconButton>
								</Link>
							);
						})}
					</Box>
				</Flex>
			</Container>
			<ImageWithFallback
				alt="Landing Page Footer"
				src={`${footerLogo}`}
				fallbackSrc={`${footerLogo}`}
				width={1920}
				height={600}
				style={{
					height: '100%',
					width: '100%',
					objectFit: 'cover',
				}}
				quality={100}
			/>
		</>
	);
};

export default Footer;
