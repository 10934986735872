export const GA_TRACKING_ID = process.env.FIREBASE_MEASUREMENT_ID;
interface EventProps {
	action: string;
	category: string;
	label: string;
	value: string;
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(window as any).gtag('config', GA_TRACKING_ID, {
		page_path: url,
	});
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: EventProps) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(window as any).gtag('event', action, {
		event_category: category,
		event_label: label,
		value: value,
	});
};
