import { ConfigState, RootState } from './types';
import { ActionType, ArkActions, ConfigActions, ConfigType, RequestType } from './actions';
import { arkDefaultValues } from '.';

export function arkReducer(rootState: RootState, action: ArkActions): RootState {
	switch (action.type) {
		case ActionType.CollectionProfileById:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					collectionProfile: action.payload,
				},
			};

		case ActionType.CollectionByUserId:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					collectionByUserId: action.payload,
				},
			};

		case ActionType.CreateNFT:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					createNFT: {
						data: action.payload.data,
						error: action.payload.error,
					},
					inRequest: rootState.ark.inRequest.filter((idx: string) => idx !== RequestType.CreateNft),
				},
			};

		case ActionType.CollectionCategories:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					categories: action.payload,
				},
			};

		case ActionType.CollectionByCategory:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					collectionByCategory: action.payload?.metadata,
					collectionByCategoryCount: action.payload?.count,
				},
			};

		case ActionType.InRequest:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					inRequest: [...rootState.ark.inRequest, action.payload],
				},
			};

		case ActionType.ShowDialogLoginRegister:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					showLoginRegisterDialog: action.payload,
				},
			};

		case ActionType.LoginRegister:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					login: action.payload,
				},
			};

		case ActionType.UserMetadata:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					user: action.payload,
					userInfo: action?.payload?.userInfo,
				},
			};

		case ActionType.UpdateProfile:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					profile: action.payload?.data,
					user: {
						...rootState.ark.user,
						userInfo: action?.payload?.data,
					},
					userInfo: action?.payload?.data,
					inRequest: rootState.ark.inRequest.filter((idx: string) => idx !== RequestType.UserSettings),
				},
			};

		case ActionType.UpdateUserInfoWithToken:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					userInfo: action?.payload?.data,
					user: {
						...rootState.ark.user,
						accessToken: action?.payload?.token,
						userInfo: action?.payload?.data,
					},
					login: {
						isLogged: true,
						token: action?.payload?.token,
					},
				},
			};

		case ActionType.UpdateCategory:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					category: action?.payload?.data,
				},
			};

		case ActionType.CollectionDetail:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					collectionInfo: action?.payload?.data,
				},
			};

		case ActionType.CollectionDetailError:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					collectionDetailError: action?.payload,
					inRequest: rootState.ark.inRequest.filter((idx: string) => idx !== RequestType.CreateCollections),
				},
			};
		case ActionType.CreateCollectionError:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					createCollectionError: action?.payload,
					createCollectionSuccess: false,
					inRequest: rootState.ark.inRequest.filter(
						(idx: string) => idx !== RequestType.CreateCollectionError && idx !== RequestType.CreateCollections
					),
				},
			};

		case ActionType.CreateCollections:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					collectionInfo: { id: action.payload?.data.collectionID },
					createCollectionError: false,
					createCollectionSuccess: true,
					inRequest: rootState.ark.inRequest.filter(
						(idx: string) => idx !== RequestType.CreateCollectionError && idx !== RequestType.CreateCollections
					),
				},
			};

		case ActionType.UpdateCollectionError:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					collectionInfo: action?.payload?.data,
				},
			};

		case ActionType.CollectionDetailError:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					collectionDetailError: action?.payload,
					inRequest: rootState.ark.inRequest.filter((idx: string) => idx !== RequestType.CreateCollections),
				},
			};
		case ActionType.CreateApplicationError:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					createApplicationError: true,
					createApplicationSuccess: false,
					inRequest: rootState.ark.inRequest.filter(
						(idx: string) => idx !== RequestType.CreateApplicationError && idx !== RequestType.CreateApplication
					),
				},
			};
		case ActionType.CreateApplication:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					profile: action.payload?.data,
					user: {
						...rootState.ark.user,
						userInfo: action?.payload?.data,
					},
					application: action?.payload?.data.applicationDescription,
					userInfo: action?.payload?.data,
					createApplicationError: false,
					createApplicationSuccess: true,
					inRequest: rootState.ark.inRequest.filter(
						(idx: string) => idx !== RequestType.CreateApplicationError && idx !== RequestType.CreateApplication
					),
				},
			};

		case ActionType.CreateCollectionError:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					createCollectionError: action?.payload,
					createCollectionSuccess: false,
					inRequest: rootState.ark.inRequest.filter(
						(idx: string) => idx !== RequestType.CreateCollectionError && idx !== RequestType.CreateCollections
					),
				},
			};
		case ActionType.CreateCollections:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					collectionInfo: action.payload?.data,
					createCollectionError: false,
					createCollectionSuccess: true,
					inRequest: rootState.ark.inRequest.filter(
						(idx: string) => idx !== RequestType.CreateCollectionError && idx !== RequestType.CreateCollections
					),
				},
			};

		case ActionType.UpdateCollectionError:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					updateCollectionError: action?.payload,
					updateCollectionSuccess: false,
					inRequest: rootState.ark.inRequest.filter(
						(idx: string) => idx !== RequestType.UpdateCollectionError && idx !== RequestType.UpdateCollections
					),
				},
			};

		case ActionType.UpdateCollections:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					collectionInfo: action.payload?.data,
					updateCollectionSuccess: true,
					updateCollectionError: false,
					inRequest: rootState.ark.inRequest.filter((idx: string) => idx !== RequestType.UpdateCollections),
				},
			};

		case ActionType.LogoutUser:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					...arkDefaultValues.ark,
				},
				user: {
					...rootState.user,
					...arkDefaultValues.user,
				},
			};

		case ActionType.InRequest:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					inRequest: [...rootState.ark.inRequest, action.payload],
				},
			};

		case ActionType.RemoveInRequest:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					inRequest: rootState.ark.inRequest.filter((idx: string) => idx !== action.payload),
				},
			};

		case ActionType.NftCollectionByCollectionId:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					nftCollection: {
						...rootState.ark.nftCollection,
						data: action?.payload?.data,
						count: action?.payload?.count,
					},
				},
			};

		case ActionType.LoadingLanding:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					landingPage: {
						...rootState.ark.landingPage,
						loading: action?.payload,
						collections: [],
						newCollections: [],
						orders: [],
					},
				},
			};

		case ActionType.Landing:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					landingPage: {
						...rootState.ark.landingPage,
						loading: false,
						collections: action?.payload?.data.collection ? action?.payload?.data?.collection : [],
						newCollections: action?.payload?.data.newCollection ? action?.payload?.data.newCollection : [],
						error: action?.payload?.error ? action.payload.message : '',
						orders: action?.payload?.data.order ? action?.payload?.data.order : [],
						success: action?.payload?.success,
					},
				},
			};
		case ActionType.FetchCollectionOrders:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					collectionOrders: { cursor: '', remaining: 0, result: [] }, // reset previous orders
				},
			};

		case ActionType.FetchCollectionOrdersSuccess:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					collectionOrders: action.payload, // set new orders
				},
			};

		case ActionType.FetchCollectionOrdersError:
			// handle error...
			return rootState;

		case ActionType.PrepareWithdrawNFT:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					nftOperations: {
						...rootState.ark.nftOperations,
						isPreparingWithdraw: true,
						prepareWithdrawError: null,
					},
				},
			};

		case ActionType.PrepareWithdrawNFTSuccess:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					nftOperations: {
						...rootState.ark.nftOperations,
						isPreparingWithdraw: false,
						prepareWithdrawError: null,
					},
				},
			};

		case ActionType.PrepareWithdrawNFTError:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					nftOperations: {
						...rootState.ark.nftOperations,
						isPreparingWithdraw: false,
						prepareWithdrawError: action.payload,
					},
				},
			};
		case ActionType.CompleteWithdrawNFT:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					nftOperations: {
						...rootState.ark.nftOperations,
						isCompletingWithdraw: true,
						completeWithdrawError: null,
					},
				},
			};
		case ActionType.CompleteWithdrawNFTSuccess:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					nftOperations: {
						...rootState.ark.nftOperations,
						isCompletingWithdraw: false,
						completeWithdrawError: null,
					},
				},
			};

		case ActionType.CompleteWithdrawNFTError:
			return {
				...rootState,
				ark: {
					...rootState.ark,
					nftOperations: {
						...rootState.ark.nftOperations,
						isCompletingWithdraw: false,
						completeWithdrawError: action.payload,
					},
				},
			};
		default:
			return rootState;
	}
}

// Config Reducer

export function configReducer(state: ConfigState, action: ConfigActions): ConfigState {
	switch (action.type) {
		case ConfigType.SiteConfig:
			return { ...state, siteConfig: action.payload };
		default:
			return state;
	}
}
