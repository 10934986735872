import React from 'react';
import { Center, Container, Flex, Box, Text } from '@chakra-ui/react';
import { Player } from '@lottiefiles/react-lottie-player';
import { ErrorProps } from 'next/error';
import { ArkErrorProps } from './types';

export const Error: React.FC<ErrorProps & ArkErrorProps> = ({ title, statusCode, onClick }) => {
	return (
		<Container maxW="10xl">
			<Flex position="relative">
				<Flex w={'100%'} margin={'auto'}>
					<Box rounded="md" width="100%" p="8" onClick={onClick}>
						<Player
							autoplay
							loop
							src="https://assets10.lottiefiles.com/packages/lf20_yrlntqy3.json"
							style={{ width: '400px' }}
						/>
						<Center>
							<Text sx={{ fontWeight: 'bold' }}>{statusCode ? statusCode : '404'}</Text>
							<Text ml={2}>| {title ? title : 'Page Not Found'}</Text>
						</Center>
					</Box>
				</Flex>
			</Flex>
		</Container>
	);
};
