import { sign, verify } from 'jsonwebtoken';
import { setTokenCookie } from './auth-cookie';

const TOKEN_SECRET = process.env.TOKEN_SECRET || '';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setLoginSession = (res: any, accessToken: string, expiry: number) => {
	const session = {
		accessToken,
		createdAt: Date.now(),
		maxAge: expiry,
	};
	try {
		const token = sign(session, TOKEN_SECRET, { expiresIn: expiry });
		setTokenCookie(res, token, expiry);
	} catch (e) {
		console.error('sign ', e);
	}
};

export const getLoginSession = (token: string): string => {
	try {
		if (token) {
			const decoded = verify(token, TOKEN_SECRET);
			if (typeof decoded === 'string') return decoded;
			return decoded.accessToken;
		}
		return '';
	} catch (e) {
		console.error('sign ', e);
	}
	return '';
};
