import {
	Box,
	Button,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { PROVIDER_PREF_KEY } from '@theark/react-common';
import { magic } from '@theark/react-common';
import { useArkContext } from '../../contexts';
import { loginRequest, RequestType, showDialogLoginRegister } from '../../store/actions';
import { selectInRequest } from '../../store/selectors';
import { ILoginProps, ProviderPreference } from '../../store/types';
import { getExternalProvider, getWeb3Provider } from '../../utils';

const validateEmail = (email: string) => {
	return email.match(
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	);
};

const RegisterSignInDialog: React.FC = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		state: { ark: arkState },
		dispatch,
	} = useArkContext();
	const toast = useToast();
	const [email, setEmail] = useState('');
	const isConnecting = selectInRequest(arkState, RequestType.LoginRegister);
	const [submitting, setSubmitting] = useState(false);
	const [, setProvider] = useLocalStorage(PROVIDER_PREF_KEY, '');

	const onCloseHandler = useCallback(() => {
		dispatch(showDialogLoginRegister(false));
		onClose();
	}, [dispatch, onClose]);

	useEffect(() => {
		if (arkState.showLoginRegisterDialog) {
			onOpen();
			if (magic) magic.preload();
		}
	}, [arkState.showLoginRegisterDialog, onOpen]);

	useEffect(() => {
		if (arkState?.user?.accessToken) {
			onCloseHandler();
		}
	}, [arkState?.user?.accessToken, onCloseHandler]);

	const onChangeHandler = useCallback(
		(e: any) => {
			if (e.target) {
				setEmail(e.target.value);
			}
		},
		[setEmail]
	);

	const onLoginHandler = async (e: any) => {
		e.preventDefault();
		if (validateEmail(email) && magic) {
			try {
				setSubmitting(true);
				const l1Provider = await getWeb3Provider((await getExternalProvider(ProviderPreference.MAGIC_LINK)) as any);
				const l1Signer = l1Provider.getSigner();

				const loginProps: ILoginProps = {
					dispatch,
					request: { email, l1Provider, l1Signer },
					type: ProviderPreference.MAGIC_LINK,
					toast: toast,
				};
				loginRequest(loginProps);
				setProvider(ProviderPreference.MAGIC_LINK);
				dispatch(showDialogLoginRegister(true));
			} catch (e) {
				console.log('Login Failed ', e);
				toast({
					title: 'Error Occurred While Email Login!',
					status: 'error',
					variant: 'arkError',
					position: 'bottom-right',
				});
				setSubmitting(false);
			}
		} else {
			toast({
				title: 'Login Error!',
				description: 'Unable to login at the moment, please reload!',
				status: 'error',
				variant: 'arkError',
				position: 'bottom-right',
			});
		}
	};

	return (
		<Modal onClose={onCloseHandler} isOpen={isOpen} isCentered variant="landing">
			<ModalOverlay />
			<ModalContent maxW="25rem">
				<ModalHeader>The ARK</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text fontWeight="bold" fontSize="2xl" mb={5}>
						Welcome Back
					</Text>
					<Box as="div">
						<Text fontWeight="bold" mb="8px">
							Email address
						</Text>
						<Input value={email} onChange={onChangeHandler} variant="form-input" placeholder="Email" />
					</Box>

					<Box as="div" w="100%" mb={5}>
						<Button
							isLoading={submitting && isConnecting}
							isDisabled={!validateEmail(email)}
							onClick={onLoginHandler}
							variant="gradient-primary"
							size="lg"
							width="100%"
							mt="6"
						>
							Sign In
						</Button>
					</Box>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default RegisterSignInDialog;
