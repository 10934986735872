import { useMemo } from 'react';
import classnames from 'classnames';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { PaginationProps } from '../../types';

export const DOTS = '...';

export const PAGE_SIZE = 25;

interface IPagination {
	totalCount: number;
	pageSize: number;
	siblingCount: number;
	currentPage: number;
}

const range = (start: number, end: number) => {
	const length = end - start + 1;
	return Array.from({ length }, (_, idx) => idx + start);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const usePagination = (props: IPagination): Array<string | number> | undefined => {
	const { totalCount, pageSize, siblingCount = 1, currentPage } = props;

	const paginationRange = useMemo(() => {
		if (totalCount === 0) {
			return [];
		}

		const totalPageCount = Math.ceil(totalCount / pageSize);
		const totalPageNumbers = siblingCount + 5;

		if (totalPageNumbers >= totalPageCount) {
			return range(1, totalPageCount);
		}

		const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
		const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

		const shouldShowLeftDots = leftSiblingIndex > 2;
		const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

		const firstPageIndex = 1;
		const lastPageIndex = totalPageCount;

		if (!shouldShowLeftDots && shouldShowRightDots) {
			const leftItemCount = 3 + 2 * siblingCount;
			const leftRange = range(1, leftItemCount);

			return [...leftRange, DOTS, totalPageCount];
		}

		if (shouldShowLeftDots && !shouldShowRightDots) {
			const rightItemCount = 3 + 2 * siblingCount;
			const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
			return [firstPageIndex, DOTS, ...rightRange];
		}

		if (shouldShowLeftDots && shouldShowRightDots) {
			const middleRange = range(leftSiblingIndex, rightSiblingIndex);
			return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
		}
	}, [totalCount, pageSize, siblingCount, currentPage]);

	return paginationRange;
};

export const Pagination = (props: PaginationProps) => {
	const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className } = props;

	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	});

	if (currentPage === 0 || !paginationRange || paginationRange.length < 2) {
		return null;
	}

	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};

	const lastPage = paginationRange[paginationRange.length - 1];
	return (
		<ul className={classnames('pagination-container', className)}>
			<li
				className={classnames('pagination-item', {
					disabled: currentPage === 1,
				})}
				onClick={onPrevious}
			>
				<div>
					<MdKeyboardArrowLeft />
				</div>
			</li>
			{paginationRange.map((pageNumber: number | string, idx: number) => {
				if (typeof pageNumber === 'string') {
					if (pageNumber === DOTS) {
						return (
							<li key={idx} className="pagination-item dots">
								&#8230;
							</li>
						);
					}
					return;
				}

				return (
					<li
						key={idx}
						className={classnames('pagination-item', {
							selected: pageNumber === currentPage,
						})}
						onClick={() => onPageChange(pageNumber)}
					>
						{pageNumber}
					</li>
				);
			})}
			<li
				className={classnames('pagination-item', {
					disabled: currentPage === lastPage,
				})}
				onClick={onNext}
			>
				<div>
					<MdKeyboardArrowRight />
				</div>
			</li>
		</ul>
	);
};
