import { Nullable } from '../types';

export const API_ENDPOINT = process.env.API_ENDPOINT || '';
export const API_ENV = process.env.API_ENV || '';
export const FRONTEND_URL = process.env.FRONTEND_URL || '';
export const NFT_STORAGE_KEY = process.env.NFT_STORAGE_KEY || '';
export const PINATA_JWT = process.env.PINATA_JWT || '';
export const IPFS = process.env.NFT_STORAGE_KEY || '';
export const IPFS_IMAGE = process.env.NEXT_PUBLIC_IPFS || '';
export const ESTUARY_API = process.env.ESTUARY_API || 'https://shuttle-4.estuary.tech';
export const CONTRACT_ADDR = process.env.NEXT_CONTRACT_ADDR || '';
export const PROVIDER_PREF_KEY = 'PROVIDER_PREFERENCE';
export const STARK_WALLET_KEY = 'STARK_WALLET';

export const getTokenHeader = (token: string) => {
	return {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + token,
	};
};

export const imageURL = (img: string): string => {
	// Regular expression to check if the string is a URL
	const isUrl = /^https?:\/\/.+/i.test(img);

	if (isUrl) {
		try {
			const url = new URL(img);
			// Extract the CID assuming it's the last part of the URL path
			const cid = url.pathname.split('/').pop();
			return `${IPFS_IMAGE}${cid}`;
		} catch (error) {
			console.error('Invalid URL:', img);
			return img; // Return original if parsing fails
		}
	}

	// If not a URL, assume it's a CID and prepend the IPFS gateway URL
	return `${IPFS_IMAGE}${img}`;
};

export const ellipsisText = (str: string, length = 50): Nullable<string> => {
	if (str) {
		return str.length >= length ? str.substr(0, length) + '...' : str;
	}
	return null;
};

export const uid = (): string => {
	return Date.now().toString(36) + Math.random().toString(36).substr(2);
};
