import { MultiValue } from 'chakra-react-select';
import { CONTRACT_ADDR } from '@theark/react-common';
import { IMX_CLIENT } from '../../utils';
import { removeRequestInProcess, requestInProcess } from '../actions';
import { IDropDown } from '../user/types';
import { MarketPlaceRequestType, MarketPlaceState } from './types';
import { ListOrdersResponse, Order } from '@imtbl/core-sdk';
import { INFT } from '../types';

export enum MarketPlaceActionType {
	MarketPlaceOrders = 'MarketPlaceOrders',
	MarketPlaceCollectionList = 'MarketPlaceCollectionList',
}
export interface MarketPlaceOrders {
	type: MarketPlaceActionType.MarketPlaceOrders;
	payload: any;
}

export type MarketPlaceActions = MarketPlaceOrders;

export const marketPlaceOrders = (payload: any): MarketPlaceOrders => ({
	type: MarketPlaceActionType.MarketPlaceOrders,
	payload,
});

export const fetchMarketPlaceCollectionOrders = async (
	dispatch: any,
	marketPlaceState: MarketPlaceState,
	searchCollection?: MultiValue<IDropDown> | [],
	filter?: string | '',
	searchText?: string,
	category?: string,
	cursor?: string,
	initialLoad = true,
	blockedNFT?: INFT[]
) => {
	dispatch(requestInProcess(MarketPlaceRequestType.GetMarketPlaceOrders));

	const ordersApi = IMX_CLIENT.ordersApi;
	const filterCollection: Array<string> = [];

	searchCollection?.forEach((item: IDropDown) => {
		filterCollection.push(item.value);
	});

	const metdataFilter = {
		...(filterCollection.length > 0 && { collection: filterCollection }),
		...(category !== 'All' && { category: [category] }),
	};

	try {
		ordersApi
			.listOrdersV3({
				status: 'active',
				...(searchText && { sellTokenName: searchText }),
				...(Object.keys(metdataFilter).length > 0 && { sellMetadata: JSON.stringify(metdataFilter) }),
				includeFees: true,
				sellTokenAddress: CONTRACT_ADDR,
				pageSize: 25,
				cursor,
				...(filter !== 'all' &&
					(filter == 'asc'
						? {
								direction: 'asc',
							}
						: {
								direction: 'desc',
							})),
			})
			.then(async (response) => {
				let orders: ListOrdersResponse = response.data;
				const getFilteredOrders = async (orders: ListOrdersResponse): Promise<Order[]> => {
					const filteredOrders: Order[] = [];

					for (const order of orders.result) {
						const isBlocked = blockedNFT?.find((nft) => nft.id === order.sell.data.token_id);
						if (!isBlocked) {
							filteredOrders.push(order);
						}
					}

					return filteredOrders;
				};

				const filteredOrders = await getFilteredOrders(orders)
					.then((filteredOrders) => {
						return filteredOrders;
					})
					.catch((err) => {
						console.error('Error:', err);
						return [];
					});

				dispatch(
					marketPlaceOrders({
						orders: {
							...orders,
							result: initialLoad
								? filteredOrders
								: [...(marketPlaceState.marketPlaceOrders?.result || []), ...filteredOrders],
							remaining: orders.remaining,
							cursor: orders.cursor,
						},
					})
				);
				dispatch(removeRequestInProcess(MarketPlaceRequestType.GetMarketPlaceOrders));
			});
	} catch (e) {
		console.log('Error ', e, category);
		dispatch(
			marketPlaceOrders({
				orders: {},
			})
		);
		dispatch(removeRequestInProcess(MarketPlaceRequestType.GetMarketPlaceOrders));
	}
};
