import { IMX_CLIENT } from '../../utils';
import { removeRequestInProcess, requestInProcess } from '../actions';
import { HistoryRequestType, HistoryState } from './types';

export enum HistoryActionType {
	TransactionHistory = 'TransactionHistory',
}
export interface TransactionHistory {
	type: HistoryActionType.TransactionHistory;
	payload: HistoryState;
}

export type HistoryActions = TransactionHistory;

export const transactionHistory = (payload: HistoryState): TransactionHistory => ({
	type: HistoryActionType.TransactionHistory,
	payload,
});

export const fetchHistory = async (dispatch: any, address: string) => {
	dispatch(requestInProcess(HistoryRequestType.GetHistoryTransactions));

	const ordersApi = IMX_CLIENT.ordersApi;
	const depositsApi = IMX_CLIENT.depositsApi;
	const withdrawalsApi = IMX_CLIENT.withdrawalsApi;
	const transfersApi = IMX_CLIENT.transfersApi;

	try {
		const ordersHistory = await ordersApi.listOrdersV3({
			includeFees: true,
			status: 'filled',
			user: address,
		});

		const depositsHistory = await depositsApi.listDeposits({
			status: 'success',
			user: address,
		});

		const withdrawalsHistory = await withdrawalsApi.listWithdrawals({
			status: 'success',
			user: address,
		});

		const transfersInHistory = await transfersApi.listTransfers({
			receiver: address,
			status: 'success',
		});

		const transfersOutHistory = await transfersApi.listTransfers({
			status: 'success',
			user: address,
		});

		dispatch(
			transactionHistory({
				ordersHistory: ordersHistory.data,
				depositsHistory: depositsHistory.data,
				withdrawalsHistory: withdrawalsHistory.data,
				transfersOutHistory: transfersOutHistory.data,
				transfersInHistory: transfersInHistory.data,
			})
		);
		dispatch(removeRequestInProcess(HistoryRequestType.GetHistoryTransactions));
	} catch (e) {
		console.log('Error in fetching history ', e);
		dispatch(
			transactionHistory({
				ordersHistory: { result: [], cursor: '', remaining: 0 },
				depositsHistory: { result: [], cursor: '', remaining: 0 },
				withdrawalsHistory: { result: [], cursor: '', remaining: 0 },
				transfersOutHistory: { result: [], cursor: '', remaining: 0 },
				transfersInHistory: { result: [], cursor: '', remaining: 0 },
			})
		);
		dispatch(removeRequestInProcess(HistoryRequestType.GetHistoryTransactions));
	}
};
