import { RootState } from '../types';
import { CollectionActions, CollectionActionType } from './actions';

export function collectionReducer(rootState: RootState, action: CollectionActions): RootState {
	switch (action.type) {
		case CollectionActionType.CollectionNotification:
			return {
				...rootState,
				collection: {
					...rootState.collection,
					createNotificationSuccess: true,
					createNotificationError: '',
					createNotificationLoading: false,
					createNotification: action.payload,
				},
			};

		case CollectionActionType.CollectionNotificationLoading:
			return {
				...rootState,
				collection: {
					...rootState.collection,
					createNotificationSuccess: false,
					createNotificationError: '',
					createNotificationLoading: true,
				},
			};

		case CollectionActionType.CollectionNotificationError:
			return {
				...rootState,
				collection: {
					...rootState.collection,
					createNotificationSuccess: false,
					createNotificationError: action.payload,
					createNotificationLoading: false,
				},
			};
		default:
			return rootState;
	}
}
