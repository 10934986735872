import { RootState } from '../types';
import { UserActions, UserActionType } from './actions';

export function userReducer(rootState: RootState, action: UserActions): RootState {
	switch (action.type) {
		case UserActionType.UserWishlist:
			return {
				...rootState,
				user: {
					...rootState.user,
					wishlist: action.payload,
				},
			};
		case UserActionType.UserCollections:
			return {
				...rootState,
				user: {
					...rootState.user,
					collections: action.payload?.data ? action.payload?.data : [],
					collectionsCount: action.payload?.data?.count || 0,
				},
			};

		case UserActionType.UserProfileCategory:
			return {
				...rootState,
				user: {
					...rootState.user,
					categories: action.payload?.data,
				},
			};

		case UserActionType.UserNftCollections:
			return {
				...rootState,
				user: {
					...rootState.user,
					nftCollections: action.payload,
				},
			};

		case UserActionType.NftCollectionsDropdown:
			return {
				...rootState,
				user: {
					...rootState.user,
					nftCollectionsListForDropdown: {
						...rootState.user.nftCollectionsListForDropdown,
						list: action.payload?.list,
						totalCount: action.payload?.totalCount,
					},
				},
			};

		default:
			return rootState;
	}
}
