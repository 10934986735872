import React, { createContext, FC, useContext, useEffect, useReducer } from 'react';
import { arkDefaultValues, configDefaultValues } from '../store';
import { ArkActions, fetchSiteConfig } from '../store/actions';
import { CollectionActions } from '../store/collection/actions';
import { collectionReducer } from '../store/collection/reducers';
import { HistoryActions } from '../store/history/actions';
import { historyReducer } from '../store/history/reducers';
import { MarketPlaceActions } from '../store/marketplace/actions';
import { marketPlaceReducer } from '../store/marketplace/reducers';
import { arkReducer } from '../store/reducers';
import { IAuxProps, RootState } from '../store/types';
import { UserActions } from '../store/user/actions';
import { userReducer } from '../store/user/reducers';
import { WalletActions } from '../store/wallet/actions';
import { walletReducer } from '../store/wallet/reducers';
import useConfigContext from './ConfigContext';

export const combineReducers = (reducers: any) => {
	return (state: any, action: any) => {
		return Object.keys(reducers).reduce((acc: any, prop: any) => {
			return {
				...acc,
				...reducers[prop]({ [prop]: acc[prop] }, action),
			};
		}, state);
	};
};
export const ArkContext = createContext<{
	state: RootState;
	dispatch: React.Dispatch<ArkActions | UserActions | WalletActions | MarketPlaceActions | CollectionActions | HistoryActions>;
}>({
	state: arkDefaultValues,
	dispatch: () => undefined,
});

const rootReducer = combineReducers({
	ark: arkReducer,
	user: userReducer,
	history: historyReducer,
	marketPlace: marketPlaceReducer,
	wallet: walletReducer,
	collection: collectionReducer,
});

export const ArkProvider: FC<IAuxProps> = ({ children }) => {
	const { state: configState, dispatch: configDispatch } = useConfigContext();
	useEffect(() => {
		if (typeof window === 'undefined') {
			global.window = {} as any;
		}
	});

	useEffect(() => {
		// If set to default config then update
		if (JSON.stringify(configState.siteConfig) === JSON.stringify(configDefaultValues.siteConfig)) {
			fetchSiteConfig(configDispatch);
		}
	}, [configDispatch, configState]);

	const [state, dispatch] = useReducer(rootReducer, arkDefaultValues);
	return (
		<>
			<ArkContext.Provider value={{ state, dispatch }}>{children}</ArkContext.Provider>
		</>
	);
};

export const useArkContext = () => {
	return useContext(ArkContext);
};

export default useArkContext;
