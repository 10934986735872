import { RootState } from '../types';
import { HistoryActions, HistoryActionType } from './actions';

export function historyReducer(rootState: RootState, action: HistoryActions): RootState {
	switch (action.type) {
		case HistoryActionType.TransactionHistory:
			return {
				...rootState,
				history: {
					...rootState.history,
					ordersHistory: action.payload.ordersHistory,
					depositsHistory: action.payload.depositsHistory,
					withdrawalsHistory: action.payload.withdrawalsHistory,
					transfersOutHistory: action.payload.transfersOutHistory,
					transfersInHistory: action.payload.transfersInHistory,
				},
			};
		default:
			return rootState;
	}
}
