import { ListDepositsResponse, ListOrdersResponse, ListTransfersResponse, ListWithdrawalsResponse } from '@imtbl/core-sdk';

export interface Transactions {
	date: Date;
	type: string; //Transfer In, Transfer Out, Purchase, Sold, Deposit, Exchange, Withdrawal
	txnId: number; //transaction_id
	image?: string;
	description: string; //description
	amount: string | number; //amount
}

export type HistoryState = {
	ordersHistory: ListOrdersResponse;
	depositsHistory: ListDepositsResponse;
	withdrawalsHistory: ListWithdrawalsResponse;
	transfersOutHistory: ListTransfersResponse;
	transfersInHistory: ListTransfersResponse;
};

export enum HistoryRequestType {
	GetHistoryTransactions = 'GetHistoryTransactions',
}
