import { RootState } from '../types';
import { MarketPlaceActions, MarketPlaceActionType } from './actions';

export function marketPlaceReducer(rootState: RootState, action: MarketPlaceActions): RootState {
	switch (action.type) {
		case MarketPlaceActionType.MarketPlaceOrders:
			return {
				...rootState,
				marketPlace: {
					...rootState.marketPlace,
					marketPlaceOrders: action.payload.orders,
					marketPlaceOrdersCount: 0,
				},
			};
		default:
			return rootState;
	}
}
