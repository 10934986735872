import { CookieSerializeOptions, serialize } from 'cookie';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setTokenCookie = (res: any, token: string, expiry: number) => {
	const cookieOptions: CookieSerializeOptions = {
		maxAge: expiry,
		httpOnly: true,
		secure: process.env.NODE_ENV === 'production',
		path: '/',
		sameSite: 'strict',
	};
	const seralized = serialize('token', token, cookieOptions);
	res.setHeader('Set-Cookie', seralized);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const clearTokenCookie = (res: any) => {
	const cookieOptions: CookieSerializeOptions = {
		maxAge: -1,
		httpOnly: true,
		secure: process.env.NODE_ENV === 'production',
		path: '/',
		sameSite: 'strict',
	};
	const seralized = serialize('token', '', cookieOptions);
	res.setHeader('Set-Cookie', seralized);
};
