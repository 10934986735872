import { RootState } from '../types';
import { WalletActions, WalletActionType } from './actions';

export function walletReducer(rootState: RootState, action: WalletActions): RootState {
	switch (action.type) {
		case WalletActionType.WalletDetailLoading:
			return {
				...rootState,
				wallet: {
					...rootState.wallet,
					walletBalanceIsLoading: true,
					walletLoadSuccess: false,
					walletLoadError: false,
					depositProcessing: false,
					depositError: false,
					depositSuccess: false,
					withdrawRequestProcessing: false,
					withdrawRequestError: false,
					withdrawRequestSuccess: false,
					withdrawCompleteProcessing: false,
					withdrawCompleteError: {
						isError: false,
						message: '',
					},
					withdrawCompleteSuccess: false,
				},
			};

		case WalletActionType.WalletDetail:
			return {
				...rootState,
				wallet: {
					...rootState.wallet,
					walletBalanceIsLoading: false,
					walletLoadSuccess: true,
					walletLoadError: false,
					walletBalance: action.payload?.usd,
					walletBalanceETH: action.payload?.ethBalance,
				},
			};

		case WalletActionType.WalletBalance:
			return {
				...rootState,
				wallet: {
					...rootState.wallet,
					balance: action.payload,
				},
			};

		case WalletActionType.Withdrawable:
			return {
				...rootState,
				wallet: {
					...rootState.wallet,
					withdrawable: action.payload?.usd,
					withdrawableETH: action.payload?.ethBalance,
				},
			};

		case WalletActionType.Preparing:
			return {
				...rootState,
				wallet: {
					...rootState.wallet,
					preparing: action.payload?.usd,
					preparingETH: action.payload?.ethBalance,
				},
			};

		case WalletActionType.WalletDetailError:
			return {
				...rootState,
				wallet: {
					...rootState.wallet,
					walletBalanceIsLoading: false,
					walletLoadSuccess: false,
					walletLoadError: true,
				},
			};

		case WalletActionType.DepositeProcessing:
			return {
				...rootState,
				wallet: {
					...rootState.wallet,
					depositProcessing: true,
					depositError: false,
					depositSuccess: false,
					walletBalanceIsLoading: false,
					walletLoadSuccess: false,
					walletLoadError: false,
					withdrawRequestProcessing: false,
					withdrawRequestError: false,
					withdrawRequestSuccess: false,
					withdrawCompleteProcessing: false,
					withdrawCompleteError: {
						isError: false,
						message: '',
					},
					withdrawCompleteSuccess: false,
				},
			};

		case WalletActionType.DepositeError:
			return {
				...rootState,
				wallet: {
					...rootState.wallet,
					depositProcessing: false,
					depositError: true,
					depositSuccess: false,
				},
			};

		case WalletActionType.DepositeSuccess:
			return {
				...rootState,
				wallet: {
					...rootState.wallet,
					depositProcessing: false,
					depositError: false,
					depositSuccess: true,
				},
			};
		case WalletActionType.WithdrawRequestProcessing:
			return {
				...rootState,
				wallet: {
					...rootState.wallet,
					depositProcessing: false,
					depositError: false,
					depositSuccess: false,
					walletBalanceIsLoading: false,
					walletLoadSuccess: false,
					walletLoadError: false,
					withdrawRequestProcessing: true,
					withdrawRequestError: false,
					withdrawRequestSuccess: false,
					withdrawCompleteProcessing: false,
					withdrawCompleteError: {
						isError: false,
						message: '',
					},
					withdrawCompleteSuccess: false,
				},
			};

		case WalletActionType.WithdrawRequestError:
			return {
				...rootState,
				wallet: {
					...rootState.wallet,
					withdrawRequestProcessing: false,
					withdrawRequestError: true,
					withdrawRequestSuccess: false,
				},
			};

		case WalletActionType.WithdrawRequestSuccess:
			return {
				...rootState,
				wallet: {
					...rootState.wallet,
					withdrawRequestProcessing: false,
					withdrawRequestError: false,
					withdrawRequestSuccess: true,
				},
			};
		case WalletActionType.WithdrawCompleteProcessing:
			return {
				...rootState,
				wallet: {
					...rootState.wallet,
					depositProcessing: false,
					depositError: false,
					depositSuccess: false,
					walletBalanceIsLoading: false,
					walletLoadSuccess: false,
					walletLoadError: false,
					withdrawRequestProcessing: false,
					withdrawRequestError: false,
					withdrawRequestSuccess: false,
					withdrawCompleteProcessing: action.payload,
					withdrawCompleteError: {
						isError: false,
						message: '',
					},
					withdrawCompleteSuccess: false,
				},
			};

		case WalletActionType.WithdrawCompleteError:
			console.log('Reducer: Setting error state and resetting processing');
			return {
				...rootState,
				wallet: {
					...rootState.wallet,
					withdrawCompleteError: action.payload,
					withdrawCompleteProcessing: false,
					withdrawCompleteSuccess: false,
				},
			};

		case WalletActionType.WithdrawCompleteSuccess:
			return {
				...rootState,
				wallet: {
					...rootState.wallet,
					withdrawCompleteProcessing: false,
					withdrawCompleteError: {
						isError: false,
						message: '',
					},
					withdrawCompleteSuccess: true,
				},
			};

		default:
			return rootState;
	}
}
