import { EthNetworkConfiguration, Magic } from 'magic-sdk';

export const ethNetwork = process.env.NEXT_NETWORK ?? 'goerli';

// Create client-side Magic instance
const createMagic = (key: string | undefined) => {
	if (typeof window === 'undefined' || key === undefined) {
		return null;
	}

	// if (ethNetwork === 'sepolia') {
	// 	return new Magic(key, {
	// 		network: {
	// 			rpcUrl: 'https://sepolia.infura.io/v3/7e920f8d1337476f81ea57c594a76baa',
	// 			chainId: 11155111,
	// 		}, // or your own custom node url
	// 	});
	// }

	return new Magic(key, {
		network: ethNetwork as EthNetworkConfiguration,
	});
};

export const magic = createMagic(process.env.NEXT_PUBLIC_MAGIC_PUBLISHABLE_KEY);
