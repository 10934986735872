export const AlertStyles = {
	// style object for base or default style
	baseStyle: {},
	// styles for different sizes ("sm", "md", "lg")
	sizes: {},
	// styles for different visual variants ("outline", "solid")
	variants: {
		arkSuccess: {
			container: {
				bgGradient: 'linear(to-r, gdl, gdr)',
			},
		},
		arkError: {
			container: {
				bgGradient: 'linear(to-r, alert_gdl, alert_gdr)',
			},
		},
	},
	// default values for `size` and `variant`
	defaultProps: {
		size: '',
		variant: '',
	},
};
