import { mode } from '@chakra-ui/theme-tools';

export const TextareaStyles = {
	// style object for base or default style
	baseStyle: {},
	// styles for different sizes ("sm", "md", "lg")
	sizes: {},
	// styles for different visual variants ("outline", "solid")
	variants: {
		'form-input': (props: any) => ({
			backgroundColor: mode('gray.400', 'rgba(255, 255, 255, 0.19)')(props),
			color: mode('gray.400', 'rgba(255, 255, 255, 1)')(props),
			borderRadius: '5px',
			border: '1.5px solid rgba(255, 255, 255, 0.8)',
			_focus: {
				border: '1.5px solid rgba(255, 255, 255, 1)',
			},
			_before: {
				backgroundColor: '#fff',
				boxSizing: 'border-box',
			},
		}),
	},
	// default values for `size` and `variant`
	defaultProps: {
		variant: '',
	},
};
