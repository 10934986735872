import { UseToastOptions } from '@chakra-ui/react';
import { ListOrdersResponse } from '@imtbl/core-sdk';
import { ethers } from 'ethers';
import { ReactElement } from 'react';
import { CollectionState } from './collection/types';
import { HistoryState } from './history/types';
import { MarketPlaceState } from './marketplace/types';
import { UserState } from './user/types';
import { WalletState } from './wallet/types';

// First define the NFTOperations interface
export interface NFTOperations {
	isPreparingWithdraw: boolean;
	prepareWithdrawError: Error | null;
	isCompletingWithdraw: boolean;
	completeWithdrawError: Error | null;
	isBuying: boolean;
	buyError: Error | null;
	isSelling: boolean;
	sellError: Error | null;
}

export type ArkState = {
	showLoginRegisterDialog: boolean;
	login: LoginType;
	user: {
		accessToken?: string;
		expiry?: number;
		userInfo?: UserMetadata;
	};
	userInfo?: UserMetadata;
	collectionInfo: CollectionInfo;
	profile: {
		success: boolean;
		error: boolean;
		data?: UserMetadata;
		errorMessage?: string;
	};
	category: CategoryData[];
	inRequest: string[];
	createCollectionError: boolean;
	collectionDetailError: boolean;
	updateCollectionError: boolean;
	updateCollectionSuccess: boolean;
	createCollectionSuccess: boolean;
	application: string;
	categories: ICategory[];
	collectionByCategory: ICollection[];
	collectionByCategoryCount: number;
	collectionProfile: ICollection;
	createApplicationSuccess: boolean;
	createApplicationError: boolean;
	nft: INFT;
	collectionByUserId: ICollection[];
	createNFT: ICreateNFT;
	nftCollection: INftCollection;
	landingPage: ILanding;
	collectionOrders: ListOrdersResponse;
	nftOperations: NFTOperations;
};

export interface ILanding {
	loading: boolean;
	collections: CollectionInfo[];
	newCollections: CollectionInfo[];
	orders: IOrdersLanding[];
	success: boolean;
	error: string;
}

export interface IOrdersLanding {
	amount_sold?: string;
	buy: IBuy;
	expiration_timestamp: string;
	order_id: number;
	sell?: ISell;
	status: string;
	timestamp: string;
	updated_timestamp: string;
	user?: string;
}

export interface IBuy {
	data: Buy;
	type: string;
}

export interface Buy {
	decimals: number;
	quantity: string;
	quantity_with_fees: string;
	token_address?: string;
}

export interface ISell {
	data: Sell;
	type: string;
}

export interface Sell {
	id: string;
	quantity: string;
	quantity_with_fees: string;
	token_address?: string;
	token_id?: string;
	properties: SellProperty;
}

export interface SellProperty {
	collection: SellCollectionData;
	image_url: string;
	name: string;
}

export interface SellCollectionData {
	icon_url: string;
	name: string;
}

export interface ICollectionLandingData {}

export interface INftCollection {
	data: any;
	count: number;
}
export interface RootState {
	ark: ArkState;
	user: UserState;
	history: HistoryState;
	marketPlace: MarketPlaceState;
	wallet: WalletState;
	collection: CollectionState;
}

interface ICreateNFT extends Record<string, unknown> {
	data: any;
	error: any;
}

interface UserMetadata {
	APPLICATION_FOR_CREATOR?: number;
	BROWSER_KEY?: string;
	CITY?: string;
	COUNTRY?: string;
	EMAIL?: string;
	ETH_ADDRESS?: string;
	LAST_NAME?: string;
	NAME?: string;
	PHONE_NUMBER?: number;
	USERNAME?: string;
	USER_ID?: number;
	ID?: number;
	USER_TYPE?: number;
	DESCRIPTION?: string;
	COVER?: string;
	AVATAR?: string;
}
interface CollectionInfo {
	id?: number;
	name?: string;
	description?: string;
	category?: string;
	icon?: string;
	cover?: string;
	website?: string;
	instagram?: string;
	facebook?: string;
	tiktok?: string;
	twitter?: string;
	userID?: string;
	categoryID?: number;
	discord?: string;
	nft_count?: number;
}

interface LoginType {
	isLogged: boolean;
	token: string;
	errorMessage?: string | undefined | null;
}

export interface CategoryData {
	description?: string;
	id?: number;
	name?: string;
	status?: string;
}
export type Nullable<T> = T | null;

export interface ILoginX {
	walletAddress?: string;
	email?: string;
	l1Signer?: ethers.providers.JsonRpcSigner;
	l1Provider?: ethers.providers.Web3Provider;
}

export interface ILoginProps {
	dispatch: React.Dispatch<any>;
	request: Nullable<ILoginX>;
	type: ProviderPreference;
	toast(defaultOptions?: UseToastOptions): void;
}

export interface ICategory {
	description: string;
	id: number;
	name: string;
	status: string;
}

export interface ICollection {
	categoryID: number;
	cover: string;
	description: string;
	icon: string;
	id: number;
	instagram: string;
	name: string;
	tiktok: string;
	twitter: string;
	userID: number;
	website: string;
	rawAmount: number;
	status: string;
	tokenId: string;
	discord: string;
	creator: string;
	minted: number;
}

export interface INFT extends UploadResponse {
	name?: string;
	description?: string;
	collectionID?: string;
	creator?: string;
	tags?: string;
	dropNFT?: boolean;
	properties?: any;
	royalties?: any;
	Attack?: string;
	Power?: string;
	TokenID?: number;
	category?: string;
	collection?: NFTCollection;
	imageAlt?: string | null;
	subtitle?: string;
	para?: string;
	token_address?: string;
	uri?: string | null;
	currency?: string;
	imageUrl?: string | null;
	categoryID?: string;
	id?: string;
	cover?: string;
	icon?: string;
	userID?: string;
	amount?: string;
	status?: string;
	tokenId?: string;
	rawAmount?: string;
}

interface NFTCollection {
	name: string;
	icon_url: string | null;
}

// Config State
export type ConfigState = {
	siteConfig: ISiteConfig[];
};

export enum ConfigKey {
	SITE_DEFAULT_HEADER = 'site_default_header',
	SITE_FOOTER_DARK = 'site_footer_dark',
	SITE_FOOTER_LIGHT = 'site_footer_light',
	SITE_LANDING_BANNER = 'site_landing_banner',
	SITE_LOGO_DARK = 'site_logo_dark',
	SITE_LOGO_LIGHT = 'site_logo_light',
	SITE_ROYALTY = 'site_royalty',
	SITE_DESC = 'site_description',
	SITE_NAME = 'site_name',
	SITE_TITLE = 'site_title',
	SOCIAL_INSTAGRAM = 'social_instagram',
	SOCIAL_FACEBOOK = 'social_facebook',
	SOCIAL_TWITTER = 'social_twitter',
}

export interface ISiteConfig {
	ADMIN_ONLY: number;
	NAME: ConfigKey;
	VALUE: string;
}

export interface IMXOrder {
	user: string;
	to?: string;
	amount_sold: string | null;
	timestamp: string | null;
	owner?: string;
}

export interface ISelect {
	label: string;
	value: string;
}

export interface IAuxProps {
	children: ReactElement | ReactElement[];
}

export const BUY_BUTTON_TEXT = 'Buy';
export const LOGIN_TO_BUY_THE_NFT = 'Login to Buy the NFT';

export enum WITHDRAWAL_ACTION {
	COMPLETE_WITHDRAWAL = 'COMPLETE_WITHDRAWAL',
	PREPARING_WITHDRAWAL = 'PREPARING_WITHDRAWAL',
}

export interface SelectedMedia {
	message?: string;
	isUploading?: boolean;
}

export interface UploadResponse {
	animation_url?: string;
	animation_url_mime_type?: string;
	download_url?: string;
	image_url?: string;
}

export enum ProviderPreference {
	METAMASK = 'metamask',
	MAGIC_LINK = 'magic_link',
}

export interface TransakResponse {
	status: any;
	eventName: string;
}
