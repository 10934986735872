import { theme as base } from '@chakra-ui/react';
// theme/index.js
import { extendTheme } from '@chakra-ui/react';

// Global style overrides
import { styles } from './styles';
// Foundational style overrides
import { colors } from './foundations/colors';

// Component style overrides
import { ButtonStyles as Button } from './components/buttons';
import { AlertStyles as Alert } from './components/alert';
import { InputStyles as Input } from './components/inputs';
import { SelectStyles as Select } from './components/selects';
import { TextareaStyles as Textarea } from './components/textareas';
import { ModalStyles as Modal } from './components/modals';

const config = {
	initialColorMode: 'dark',
	useSystemColorMode: false,
	cssVarPrefix: 'ark',
};

const breakpoints = {
	sm: '320px',
	md: '768px',
	lg: '960px',
	xl: '1200px',
	'2xl': '1536px',
};

const overrides = {
	fonts: {
		heading: `Inter, ${base.fonts?.heading}`,
		body: `Inter, ${base.fonts?.body}`,
	},
	breakpoints,
	config,
	styles,
	// Other foundational style overrides go here
	...colors,
	components: {
		Alert,
		Button,
		Input,
		Modal,
		Select,
		Textarea,
		// Checkbox,
		// Other components go here
	},
};

export default extendTheme(overrides);
