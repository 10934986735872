export const ButtonStyles = {
	// style object for base or default style
	baseStyle: {},
	// styles for different sizes ("sm", "md", "lg")
	sizes: {},
	// styles for different visual variants ("outline", "solid")
	variants: {
		'gradient-primary': {
			bgGradient: 'linear(to-r, gdl, gdr)',
		},
		'gradient-secondry': {
			bgGradient: 'linear-gradient(29deg, #bcc866 0%, #689c90)',
		},
		'normal-white-border': {
			border: '1px solid #ffffff',
			bg: '#505050',
		},
		'button-link': {
			fontWeight: '100',
			fontSize: '1rem',
			cursor: 'pointer',
			padding: '10px',
		},

		'button-link-active': {
			fontWeight: '100',
			cursor: 'pointer',
			padding: '10px',
			borderBottom: '1px solid',
			borderImage: 'linear-gradient(29deg, #bcc866 0%, #689c90)',
			borderImageSlice: '1',
		},
	},
	// default values for `size` and `variant`
	defaultProps: {
		size: '',
		variant: '',
	},
};
