import React from 'react';
import { ChakraProvider, cookieStorageManagerSSR, localStorageManager } from '@chakra-ui/react';
import { ArkProvider } from '../../contexts';
import theme from '../../theme';
import Footer from '../Footer';
import { ArkHeader } from '../Header/ArkHeader';
import { ConfigProvider } from '../../contexts/ConfigContext';
import { GetServerSideProps } from 'next';

const Layout: React.FC<any> = ({ children, cookies, userToken }) => {
	const manager = typeof cookies === 'string' ? cookieStorageManagerSSR(cookies) : localStorageManager;

	return (
		<>
			<ConfigProvider>
				<ArkProvider>
					<ChakraProvider theme={theme} colorModeManager={manager}>
						<ArkHeader userToken={userToken} />
						{children}
						<Footer />
					</ChakraProvider>
				</ArkProvider>
			</ConfigProvider>
		</>
	);
};

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
	return {
		props: {
			// first time users will not have any cookies and you may not return
			// undefined here, hence ?? is necessary
			cookies: req.headers.cookie ?? '',
		},
	};
};

export default Layout;
