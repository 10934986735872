import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import {
	Box,
	Button,
	Center,
	Collapse,
	Container,
	Flex,
	HStack,
	Icon,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	PopoverTrigger as OrigPopoverTrigger,
	Popover,
	PopoverContent,
	Spacer,
	Stack,
	useBreakpointValue,
	useColorModeValue,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { useArkContext } from '../../contexts';
import {
	getUserInfoWithToken,
	loginRequest,
	notificationPermission,
	requestInProcess,
	RequestType,
	showDialogLoginRegister,
} from '../../store/actions';
import ProfileOptions from '../ProfileOptions';
import { ILoginProps, ProviderPreference } from '../../store/types';
import { ChevronDownIcon, CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { selectInRequest, selectUserInfo } from '../../store/selectors';
import fallBackLogo from '../../assets/images/logo.svg';
import { getMessaging, isSupported, onMessage } from 'firebase/messaging';
import { firebaseApp } from '../../common/firebase';
import RegisterSignInDialog from './RegisterSignInDialog';
import { magic, PROVIDER_PREF_KEY } from '@theark/react-common';
import { getExternalProvider, getWeb3Provider } from '../../utils';
import { ExternalProvider } from '@ethersproject/providers';
import { useLocalStorage } from 'usehooks-ts';
import Image from 'next/image';

const PopoverTrigger: React.FC<{ children: React.ReactNode }> = OrigPopoverTrigger;

interface NavItem {
	label: string;
	children?: Array<NavItem>;
	path?: string;
}

export const ArkHeader: React.FC<any> = ({ userToken }) => {
	const {
		state: { ark: arkState },
		dispatch,
	} = useArkContext();
	const { isOpen, onToggle } = useDisclosure();
	const router = useRouter();
	const userInfo = selectUserInfo(arkState);
	const isLogged: boolean = Boolean(userToken || arkState?.user.accessToken);
	const toast = useToast();
	const isConnecting = selectInRequest(arkState, RequestType.LoginRegister);
	const [, setProvider] = useLocalStorage(PROVIDER_PREF_KEY, '');

	const NAV_ITEMS = (): Array<NavItem> => {
		const menuArray: NavItem[] = [
			{
				label: 'Home',
				path: '/',
			},
			{
				label: 'Marketplace',
				path: '/marketplace',
			},
			{
				label: 'Story',
				path: '/story',
			},
			{
				label: 'Social',
				path: '/social',
			},
		];

		if (isLogged && arkState.userInfo?.USER_TYPE == 1) {
			menuArray.push({
				label: 'Collection',
				children: [
					{
						label: 'Create',
						path: '/collection/create',
					},
					{
						label: 'Mint NFT',
						path: '/nft/create',
					},
				],
			});
		}

		return menuArray;
	};

	const openDialogHandler = async (e: any) => {
		e.preventDefault();
		dispatch(requestInProcess(RequestType.LoginRegister));
		dispatch(showDialogLoginRegister(true));
	};

	// web3 methods
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleChainChanged = (_chainID: any) => {
		// TODO: implement
		console.log('chain changed', _chainID);
		// We recommend reloading the page, unless you must do otherwise
		// router.reload();
	};

	const handleAccountsChanged = (accounts: Array<unknown>) => {
		if (accounts.length === 0) {
			// MetaMask is locked or the user has not connected any accounts
			console.log('Please connect to MetaMask.');
		} else if (arkState.userInfo?.ETH_ADDRESS && accounts[0] !== arkState.userInfo?.ETH_ADDRESS) {
			// TODO: logout user
			console.log('account change detected, logout user?');
		}
	};

	const onWeb3LoginHandler = async () => {
		const metaMaskProvider = (await getExternalProvider(ProviderPreference.METAMASK)) as ExternalProvider;
		if (metaMaskProvider.request) {
			try {
				dispatch(requestInProcess(RequestType.LoginRegister));
				// get user account
				await metaMaskProvider
					.request({ method: 'eth_requestAccounts' })
					.then(handleAccountsChanged)
					.catch((error) => {
						if (error.code === 4001) {
							// EIP-1193 userRejectedRequest error
							toast({
								title: 'Please connect to MetaMask.',
								status: 'error',
								variant: 'arkError',
								position: 'bottom-right',
							});
						} else {
							console.error(error);
						}
					});
				const l1Provider = await getWeb3Provider(metaMaskProvider);

				const l1Signer = l1Provider.getSigner();
				const l1Address = await l1Signer.getAddress();

				try {
					const loginProps: ILoginProps = {
						dispatch,
						request: { walletAddress: l1Address.toLowerCase(), l1Provider, l1Signer },
						type: ProviderPreference.METAMASK,
						toast: toast,
					};
					loginRequest(loginProps);
					setProvider(ProviderPreference.METAMASK);
				} catch (e) {
					console.log('Login Failed ', e);
				}
			} catch (e: any) {
				toast({
					title: 'Wallet Error!',
					description: e.toString(),
					status: 'error',
					variant: 'arkError',
					position: 'bottom-right',
				});
			}
		} else {
			toast({
				title: 'No Supported Web3 Wallet Found!',
				status: 'error',
				variant: 'arkError',
				position: 'bottom-right',
			});
		}
	};
	const syncMessaging = async () => {
		if (await isSupported()) {
			const messages = getMessaging(firebaseApp);
			onMessage(messages, (payload: any) => {
				console.log('Message received. ', payload);
			});
		}
	};

	useEffect(() => {
		const checkUserAndSession = async () => {
			if (userToken && (!arkState?.userInfo || Object.keys(arkState?.userInfo).length === 0)) {
				// Check magic session before getting user info
				if (magic) {
					const isLoggedIn = await magic.user.isLoggedIn();
					if (!isLoggedIn) {
						localStorage.clear();
						router.push('/');
						return;
					}
				}
				getUserInfoWithToken(userToken, dispatch);
			}
			if (arkState.userInfo && arkState?.userInfo.ID && !arkState.userInfo.BROWSER_KEY) {
				notificationPermission(userInfo, arkState, toast, dispatch);
			}
			syncMessaging();
		};

		checkUserAndSession();
	}, [userToken, arkState?.userInfo, dispatch]);

	const DesktopNav = () => {
		return (
			<HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
				{NAV_ITEMS().map((navItem) => (
					<Box key={navItem.label}>
						<Popover trigger={'hover'} placement={'bottom-start'} isLazy>
							<PopoverTrigger>
								<Button
									variant={router.pathname == navItem.path ? 'button-link-active' : 'button-link'}
									onClick={(e: any) => {
										e.preventDefault();
										if (navItem.path) router.push(navItem.path);
									}}
									_hover={{
										textDecoration: 'none',
									}}
								>
									{navItem.label}
								</Button>
							</PopoverTrigger>

							{navItem.children && (
								<PopoverContent border={0} boxShadow={'xl'} p={4} rounded={'xl'} maxW={'150px'}>
									<Menu>
										{navItem.children.map((child: any) => (
											<DesktopSubNav key={child.label} {...child} />
										))}
									</Menu>
								</PopoverContent>
							)}
						</Popover>
					</Box>
				))}
			</HStack>
		);
	};

	const DesktopSubNav = ({ label, path }: NavItem) => {
		return (
			<MenuItem
				as={Button}
				variant={router.pathname == path ? 'button-link-active' : 'button-link'}
				onClick={(e: any) => {
					e.preventDefault();
					if (path) router.push(path);
				}}
				_hover={{
					bgGradient: 'linear-gradient(29deg, #bcc866 0%, #689c90)',
				}}
			>
				{label}
			</MenuItem>
		);
	};

	const MobileNav = () => {
		return (
			<Stack bg={useColorModeValue('white', '#202020')} p={4} display={{ md: 'none' }}>
				{NAV_ITEMS().map((navItem: any) => (
					<MobileNavItem key={navItem.label} {...navItem} />
				))}
			</Stack>
		);
	};

	const MobileNavItem = ({ label, children, path }: NavItem) => {
		const { isOpen, onToggle } = useDisclosure();

		return (
			<Stack spacing={4} onClick={children && onToggle}>
				<Flex
					py={2}
					as={Button}
					variant={router.pathname == path ? 'button-link-active' : 'button-link'}
					onClick={() => {
						if (path) router.push(path);
					}}
					justify={'space-between'}
					align={'center'}
					_hover={{
						textDecoration: 'none',
					}}
				>
					{label}
					{children && (
						<Icon
							as={ChevronDownIcon}
							transition={'all .25s ease-in-out'}
							transform={isOpen ? 'rotate(180deg)' : ''}
							w={6}
							h={6}
						/>
					)}
				</Flex>

				<Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
					<Stack
						mt={2}
						pl={4}
						borderLeft={1}
						borderStyle={'solid'}
						borderColor={useColorModeValue('gray.200', 'gray.700')}
						align={'start'}
					>
						{children &&
							children.map((child: any) => (
								<Button
									key={child.label}
									variant={router.pathname == child.path ? 'button-link-active' : 'button-link'}
									py={2}
									onClick={() => {
										if (child.path) router.push(child.path);
									}}
								>
									{child.label}
								</Button>
							))}
					</Stack>
				</Collapse>
			</Stack>
		);
	};

	const width = useBreakpointValue({ base: 100, md: 240 });

	return (
		<Container maxW="container.xl">
			<HStack spacing={{ base: 15, md: 4 }} mb={{ md: '-15' }}>
				<Flex display={{ base: 'flex', md: 'none', lg: 'none' }} flex={{ base: 1, md: 'auto' }} ml={{ base: '10px' }}>
					<IconButton
						onClick={onToggle}
						icon={isOpen ? <CloseIcon w={13} h={13} /> : <HamburgerIcon w={15} h={15} />}
						variant={'ghost'}
						aria-label={'Toggle Navigation'}
					/>
				</Flex>
				<Center>
					<Image
						onClick={() => router.push('/')}
						alt="logo"
						src={fallBackLogo}
						width={width}
						height={width}
						priority
						quality={100}
						style={{ cursor: 'pointer', objectFit: 'cover' }}
					/>
				</Center>
				<Spacer />
				{/* <Box w="500px" display={{ base: 'none', xl: 'flex' }} alignItems="center">
					<InputGroup>
						<InputLeftElement pointerEvents="none">
							<Icon as={IoIosSearch} color="gray.300" />
						</InputLeftElement>
						<Input type="text" variant="search" placeholder="Search items and collections" />
					</InputGroup>
				</Box> */}
				<Spacer />
				<Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
					<Flex display={{ base: 'none', md: 'flex' }} ml={10}>
						<DesktopNav />
					</Flex>
				</Flex>

				<Flex flex={{ base: 1 }} justify="right" marginRight={{ base: '15px', md: '30px' }}>
					{isLogged ? (
						<Box ml={{ md: 10 }} display="flex" alignItems="center" cursor="pointer">
							<ProfileOptions />
						</Box>
					) : (
						<Menu>
							<MenuButton as={Button} isLoading={isConnecting} loadingText="Logging In" variant="button-link">
								Login / SignUp
							</MenuButton>
							<MenuList>
								<MenuItem
									_hover={{
										bgGradient: 'linear-gradient(29deg, #bcc866 0%, #689c90)',
									}}
									onClick={openDialogHandler}
								>
									Email
								</MenuItem>
								<MenuItem
									_hover={{
										bgGradient: 'linear-gradient(29deg, #bcc866 0%, #689c90)',
									}}
									onClick={onWeb3LoginHandler}
								>
									Web3 Wallet
								</MenuItem>
							</MenuList>
						</Menu>
					)}
				</Flex>
			</HStack>

			<Collapse in={isOpen} animateOpacity>
				<MobileNav />
			</Collapse>
			{arkState.showLoginRegisterDialog && <RegisterSignInDialog />}
		</Container>
	);
};
