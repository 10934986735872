import React from 'react';
import { Center, Container, Flex, Box } from '@chakra-ui/react';
import { Player } from '@lottiefiles/react-lottie-player';
import { ArkErrorProps } from './types';

export const AccessDenied: React.FC<ArkErrorProps> = ({ onClick }) => {
	return (
		<Container maxW="2xl">
			<Flex position="relative">
				<Flex w={'100%'} margin={'auto'}>
					<Box rounded="md" width="100%" p="8" onClick={onClick}>
						<Player
							autoplay
							loop
							src="https://assets3.lottiefiles.com/packages/lf20_jnhl4zb7.json"
							style={{ height: '300px', width: '300px' }}
						/>
						<Center>
							<b>403 |</b> &nbsp; Access Denied for this Page
						</Center>
					</Box>
				</Flex>
			</Flex>
		</Container>
	);
};
