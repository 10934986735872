/* eslint-disable @typescript-eslint/no-explicit-any */
export const ChakraDropdownStyle = {
	container: (provided: any) => ({
		...provided,
		background: 'gray.900!important',
		borderRadius: '5px',
		border: '1.5px solid rgba(255, 255, 255, 0.5)',
		_focus: {
			border: '1.5px solid rgba(255, 255, 255, 1)',
		},
	}),
	option: (provided: any) => ({
		...provided,
		background: 'rgba(255, 255, 255, 0.19)!important',
		color: 'gray.900',
		_hover: {
			background: 'gray.300',
		},
	}),
	menuList: (provided: any) => ({
		...provided,
		background: 'linear-gradient(29deg, #bcc866 0%, #689c90)!important',
	}),
	singleValue: (provided: any) => ({
		...provided,
		background: 'rgba(255, 255, 255, 0.19)!important',
		color: 'rgba(255, 255, 255, 1)',
	}),
	multiValue: (provided: any) => ({
		...provided,
		background: 'rgba(255, 255, 255, 0.19)!important',
		color: 'rgba(255, 255, 255, 1)',
	}),
};
