import { ListOrdersResponse } from '@imtbl/core-sdk';
import axios from 'axios';
import { ethers } from 'ethers';
import { CONTRACT_ADDR, getTokenHeader, imageURL } from '@theark/react-common';
import { removeRequestInProcess, requestInProcess } from '../actions';
import { IMXOrder, INFT } from '../types';
import { UserRequestType, WishlistNFT } from './types';
import { IMX_CLIENT } from '../../utils';

export enum UserActionType {
	UserCollections = 'UserCollections',
	UserProfileCategory = 'UserProfileCategory',
	UserNftCollections = 'UserNftCollections',
	NftCollectionsDropdown = 'NftCollectionsDropdown',
	UserWishlist = 'UserWishlist',
}
export interface UserCollections {
	type: UserActionType.UserCollections;
	payload: any;
}

export interface UserProfileCategory {
	type: UserActionType.UserProfileCategory;
	payload: any;
}

export interface UserNftCollections {
	type: UserActionType.UserNftCollections;
	payload: any;
}

export interface NftCollectionsDropdown {
	type: UserActionType.NftCollectionsDropdown;
	payload: any;
}

export interface UserWishlist {
	type: UserActionType.UserWishlist;
	payload: WishlistNFT;
}

export type UserActions = UserCollections | UserProfileCategory | UserNftCollections | NftCollectionsDropdown | UserWishlist;

export const userCollections = (payload: any): UserCollections => ({
	type: UserActionType.UserCollections,
	payload,
});

export const userProfileCategory = (payload: any): UserProfileCategory => ({
	type: UserActionType.UserProfileCategory,
	payload,
});

export const userNftCollections = (payload: any): UserNftCollections => ({
	type: UserActionType.UserNftCollections,
	payload,
});

export const nftCollectionsDropdown = (payload: any): NftCollectionsDropdown => ({
	type: UserActionType.NftCollectionsDropdown,
	payload,
});

export const userWishlist = (payload: WishlistNFT): UserWishlist => ({
	type: UserActionType.UserWishlist,
	payload,
});

export const fetchNftCollectionsDropdown = async (dispatch: any) => {
	try {
		const headers = {
			'Content-Type': 'application/json',
		};

		const res = await axios(`/api/collection/category?perPage=1000`, {
			method: 'GET',
			headers,
		});

		if (res?.data?.success) {
			dispatch(
				nftCollectionsDropdown({
					list: res?.data?.metadata,
					totalCount: res?.data?.count,
				})
			);
		}
	} catch (e) {
		console.log(e);
	}
};

export const getNftTransactionHistory = async (tokenId: string): Promise<Array<IMXOrder>> => {
	const ordersApi = IMX_CLIENT.ordersApi;
	const promiseTransactionHistory = await ordersApi.listOrdersV3({
		status: 'filled',
		sellTokenAddress: CONTRACT_ADDR,
		sellTokenId: tokenId,
		pageSize: 5,
	});
	const response: ListOrdersResponse = promiseTransactionHistory.data;

	let orders: Array<IMXOrder> = [];
	for (let i = 0; i < response.result.length; i++) {
		let ownerName: string | null | undefined = '';
		const user = response.result[i] ? response.result[i].user : undefined;

		if (user) {
			ownerName = await getUserInfoByWalletAddresses(user);
		}

		const tmpOrder: IMXOrder = {
			user: response.result[i].user,
			to: response.result[i + 1] ? response.result[i + 1].user : undefined,
			amount_sold: ethers.utils.formatEther(response.result[i].buy.data.quantity?.toString()),
			timestamp: response.result[i].timestamp,
			owner: ownerName ? ownerName?.toString() : user?.substr(1, 6) + '...',
		};
		orders.push(tmpOrder);
	}
	return orders;
};

export const getNftDetailsByID = async (tokenId: string): Promise<INFT> => {
	let nft = {} as INFT;
	try {
		const headers = {
			'Content-Type': 'application/json',
		};

		const res = await axios(`/api/nft/${tokenId}`, {
			method: 'GET',
			headers,
		});

		nft = res.data.metadata;
	} catch (e) {
		console.log(e);
	}
	return nft;
};

export const fetchAssetByNFTID = async (tokenId: string) => {
	const assetsApi = IMX_CLIENT.assetApi;
	const nftDetails = (
		await assetsApi.getAsset({
			tokenAddress: CONTRACT_ADDR,
			tokenId: tokenId,
			includeFees: true,
		})
	).data;

	return nftDetails;
};

export const fetchOrdersByNFTID = async (nftID: string) => {
	const ordersApi = IMX_CLIENT.ordersApi;
	const orders = (
		await ordersApi.listOrdersV3({
			status: 'active',
			sellTokenAddress: CONTRACT_ADDR,
			sellTokenId: nftID,
		})
	).data;

	return orders;
};

export const fetchUserNftCollections = async (
	dispatch: any,
	userId: string,
	assetCursor?: string,
	searchText?: string,
	searchCollection?: any,
	filter?: any,
	category?: string,
	pageSize?: number
) => {
	try {
		dispatch(requestInProcess(UserRequestType.NftUserCollections));
		dispatch(
			userNftCollections({
				list: [],
				cursor: '',
				remaining: 0,
			})
		);

		const assetsApi = IMX_CLIENT.assetApi;

		const filterCollection: Array<string> = [];
		searchCollection?.forEach((item: any) => {
			filterCollection.push(item.value);
		});

		const metdataFilter = {
			...(filterCollection.length > 0 && { collection: filterCollection }),
			...(category !== 'All' && { category: [category] }),
		};

		const assets = (
			await assetsApi.listAssets({
				...(searchText && { name: searchText }),
				...(Object.keys(metdataFilter).length > 0 && { metadata: JSON.stringify(metdataFilter) }),
				user: userId,
				collection: CONTRACT_ADDR,
				...(pageSize && { pageSize: pageSize }),
				...(assetCursor && { cursor: assetCursor }),
				...(filter !== 'all' &&
					(filter == 'asc'
						? {
								direction: 'asc',
							}
						: {
								direction: 'desc',
							})),
			})
		).data;

		dispatch(userNftCollections(assets));
		dispatch(removeRequestInProcess(UserRequestType.NftUserCollections));
	} catch (e: any) {
		console.log('My Nft Collections ', e, category);
		dispatch(userNftCollections([]));
		dispatch(removeRequestInProcess(UserRequestType.NftUserCollections));
	}
};

export const fetchUserCollections = async (dispatch: any, categoryId: any, userId: any, keyword: any) => {
	try {
		dispatch(requestInProcess(UserRequestType.MyCollections));
		const headers = {
			'Content-Type': 'application/json',
		};
		const filters = [];
		if (userId) {
			filters.push(`userId=${userId}`);
		}
		if (categoryId) {
			filters.push(`categoryId=${categoryId}`);
		}
		if (keyword) {
			filters.push(`keyword=${keyword}`);
		}
		const res = await axios(`/api/collection/user?${filters.join('&')}`, {
			method: 'GET',
			headers,
		});

		if (res?.data?.success) {
			dispatch(
				userCollections({
					data: res?.data?.metadata,
					error: res?.data?.error,
					count: res?.data?.count,
				})
			);
		} else {
			dispatch(
				userCollections({
					data: [],
					error: res?.data?.error,
					count: 0,
				})
			);
		}
	} catch (e) {
		dispatch(
			userCollections({
				data: [],
				error: e,
				count: 0,
			})
		);
	}
	dispatch(removeRequestInProcess(UserRequestType.MyCollections));
};

export const fetchUserProfileCategory = async (dispatch: any) => {
	try {
		const headers = {
			'Content-Type': 'application/json',
		};

		const res = await axios(`/api/category`, {
			method: 'GET',
			headers,
		});

		if (res?.data?.success) {
			dispatch(
				userProfileCategory({
					data: res?.data?.metadata,
				})
			);
		}
	} catch (e) {
		console.log(e);
		console.log('Unable to fetch user Info.');
	}
};

const cacheUserResponse: { [key: string]: any } = {};

export const getUserDetails = async (walletAddress: string) => {
	if (cacheUserResponse[walletAddress]) {
		return cacheUserResponse[walletAddress];
	}

	try {
		const res = await axios.get(`/api/users?walletAddress=${walletAddress}`);
		if (res?.data.success) {
			cacheUserResponse[walletAddress] = res?.data?.metadata[0];
			return cacheUserResponse[walletAddress];
		}
	} catch (error) {
		console.log('Error fetching user details:', error);
	}

	return null;
};

interface IUserNameImage {
	Avatar: string;
	Cover: string;
	Name: string;
}

export const getUserNameAndImage = async (walletAddress: string): Promise<IUserNameImage> => {
	let data: IUserNameImage = {} as IUserNameImage;
	try {
		const response = await getUserDetails(walletAddress);
		if (response) {
			data = {
				Name: response?.NAME,
				Cover: imageURL(response?.COVER),
				Avatar: imageURL(response?.AVATAR),
			};
		}
	} catch (e: any) {
		console.log('Wallet Address Error ', e);
	}
	return data;
};

export const getUserInfoByWalletAddresses = async (walletAddress: string): Promise<string> => {
	try {
		const response = await getUserDetails(walletAddress);
		if (response) {
			return response['NAME'];
		}
	} catch (e: any) {
		console.log('Wallet Address Error ', e);
	}
	return '';
};

export const fetchUserWishlist = async (dispatch: any, token: string, page: number) => {
	dispatch(requestInProcess(UserRequestType.UserWishlist));

	const headers = getTokenHeader(token || '');
	try {
		let items: INFT[] = [];
		const res = await axios.get('/api/wishlist', {
			params: {
				page,
			},
			headers,
		});

		if (res.data.metadata.length > 0) {
			items = await Promise.all<INFT[]>(res.data.metadata.map((tokenId: number) => getNftDetailsByID(tokenId.toString())));
		}

		dispatch(
			userWishlist({
				items,
				totalCount: res.headers['x-match-count'],
			})
		);
	} catch (error) {
		console.log('wishlist error', error);
	} finally {
		dispatch(removeRequestInProcess(UserRequestType.UserWishlist));
	}
};
