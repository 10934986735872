import React, { createContext, FC, useContext, useEffect, useReducer } from 'react';
import { configDefaultValues } from '../store';
import { ConfigActions } from '../store/actions';
import { configReducer } from '../store/reducers';
import { ConfigState, IAuxProps } from '../store/types';

export const ConfigContext = createContext<{
	state: ConfigState;
	dispatch: React.Dispatch<ConfigActions>;
}>({
	state: configDefaultValues,
	dispatch: () => undefined,
});

export const ConfigProvider: FC<IAuxProps> = ({ children }) => {
	useEffect(() => {
		if (typeof window === 'undefined') {
			global.window = {} as any;
		}
	});

	const [state, dispatch] = useReducer(configReducer, configDefaultValues);
	return <ConfigContext.Provider value={{ state, dispatch }}>{children}</ConfigContext.Provider>;
};

export const useConfigContext = () => {
	return useContext(ConfigContext);
};

export default useConfigContext;
