import { CategoryData, ICollection, INFT } from '../types';

export type UserState = {
	categories: CategoryData[];
	collections: ICollection[];
	collectionsCount: number;
	nftCollections?: IUserNftCollection;
	nftCollectionsListForDropdown?: INftCollectionDropDown;
	wishlist: WishlistNFT;
};

export interface WishlistNFT {
	items: INFT[];
	totalCount: number | string;
}

export interface IUserNftCollection {
	result: ICollection[];
	cursor: string;
	remaining: number;
}

export interface INftCollectionDropDown {
	list: IDropDown[];
	totalCount: number;
}
export interface IDropDown {
	label: string;
	value: string;
}

export enum UserRequestType {
	NftUserCollections = 'NftUserCollections',
	MyCollections = 'MyCollections',
	UserWishlist = 'UserWishlist',
}
